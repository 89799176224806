<template>
  <div>
    <b-navbar toggleable="lg" type="dark" class="upper-navbar">
      <div class="parent">
        <div class="hamburger" @click="$emit('openSidebar')">
          <b-icon icon="list"></b-icon>
        </div>
      </div>

      <b-container>
        <b-navbar-brand class="d-flex" :to="getDashboardDestination()">
          <b> <b-img src="@/assets/logo.png" /> {{ getUserName }}</b>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-nav-form class="ml-auto">
            <b-form-input
              class="mr-sm-2"
              v-model="personelFilter.where"
              placeholder="TCKN / Ad-Soyad ile arama yap"
            ></b-form-input>
            <b-button class="my-2 my-sm-0" type="submit" @click="searchBySSN"
              ><b-icon-search
            /></b-button>
          </b-nav-form>
        </b-collapse>
      </b-container>
      <b-navbar-nav>
        <b-button
          variant="outlined-danger"
          class="logout"
          size="lg"
          @click="logout"
        >
          <b-icon-power />
        </b-button>
      </b-navbar-nav>
    </b-navbar>
  </div>
  <!-- <div>
    <b-navbar toggleable="lg" type="dark" variant="info" class="upper-navbar">
      <b-container>
        <b-navbar-brand class="d-flex" :to="getDashboardDestination()">
          <b>
            <i> Personel Yönetim Sistemi </i>
          </b>
        </b-navbar-brand>

        <b-collapse id="nav-collapse" is-nav>
          <b-nav-form class="ml-auto">
            <b-form-group>
              <b-form-input
                size="md"
                v-model="personelFilter.where"
                style="min-width: 14rem"
                placeholder="TCKN / Ad-Soyad ile arama yap"
              ></b-form-input>
              <b-button
                size="md"
                class="my-2 my-sm-0"
                type="submit"
                @click="searchBySSN"
                ><b-icon-search
              /></b-button>
            </b-form-group>
          </b-nav-form>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div> -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      "getPersonelFilter",
      "getUser",
      "getClubImage",
      "getPersonelImage",
      "getUnreadMessageCount",
      "checkToken",
      "getScrollingText",
    ]),
    personelFilter: {
      get() {
        return this.getPersonelFilter;
      },
      set(value) {
        this.$store.commit("updatePersonelFilter", value);
      },
    },
    getUserName: {
      get() {
        return this.getUser.userName;
      },
    },
  },
  name: "Navbar",
  data() {
    return {
      ssn: "",
      value: 5,
      scrollIndex: 0,
    };
  },
  created() {
    this.$store.dispatch("initScrollingText").then(() => {
      setInterval(this.repeatText, 5000);
    });
    if (this.hasRole(["club"])) {
      this.$store.dispatch("initClubImages", this.getUser.id);
    } else if (this.hasRole(["personel", "provincialRepresentative"])) {
      this.$store.dispatch("initPersonelImage", this.getUser.id);
    }
  },
  methods: {
    searchBySSN(event) {
      event.preventDefault();

      if (!(this.$router.history.current.path == "/personel")) {
        this.$router.push({ name: "Personel" });
      }
      this.$store.dispatch("initPersonel");
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    repeatText() {
      if (this.scrollIndex < this.getScrollingText.length - 1) {
        this.scrollIndex++;
      } else {
        this.scrollIndex = 0;
      }
    },
    logout() {
      this.$store.dispatch("logout");
    },
    hasRole(roles) {
      let vals = [];
      roles.forEach((role) => {
        if (role == "federation") {
          vals.push(this.checkToken.isFederation);
        } else if (role == "personel") {
          vals.push(this.checkToken.isPersonel);
        } else if (role == "club") {
          vals.push(this.checkToken.isClub);
        } else if (role == "provincialRepresentative") {
          vals.push(this.checkToken.isProvincialRepresentative);
        }
      });
      return vals.includes(true);
    },
    editPersonel() {
      this.$router.push({
        name: "EditPersonel",
        params: { id: this.getUser.id },
      });
    },
    getDashboardDestination() {
      if (this.hasRole(["federation"])) {
        return "/pending-approvals";
      } else if (this.hasRole(["club"])) {
        return { name: "ClubDetail", params: { id: this.getUser.id } };
      } else {
        return { name: "PersonelDetail", params: { id: this.getUser.id } };
      }
    },
    editClub() {
      this.$router.push({
        name: "EditClub",
        params: { id: this.getUser.id },
      });
    },
  },
};
</script>

<style>
.menu-button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.dropdown-button {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  min-width: 16rem;
}
.dropdown-parent {
  min-width: 16rem;
}
.dropdown-parent:hover .dropdown-menu {
  display: block;
  margin-top: -3.25rem;
  min-width: 16rem;
}
.upper-navbar {
  min-height: 6rem;
  background: rgb(5, 114, 184);
  background: linear-gradient(#2c89e6, #226bb3);
}
.user-card {
  width: 17rem;
  height: 5rem;
  padding: 1rem;
  align-content: center;
  background: rgb(5, 114, 184);
}

.message-button {
  color: rgb(224, 224, 224);
}
.message-button:hover {
  color: white;
}
.float-left {
  background: linear-gradient(#2e6f9a, #3d93cd);
}
.float-right {
  float: right;
}

.parent {
  height: 4rem;
}
.hamburger:hover {
  color: rgb(64, 142, 205);
  cursor: pointer;
  transition: all 0.5s;
  filter: drop-shadow(0 0 0.75rem darkturquoise);
}
.logout:hover {
  color: rgb(122, 38, 38);
  transition: all 0.5s;
  filter: drop-shadow(0 0 0.75rem crimson);
}
.logout,
.hamburger {
  color: rgb(6, 78, 136);
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  width: 6rem;
  height: 4rem;
  transition: all 0.5s;
}
</style>