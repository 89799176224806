<template>
  <div>
    <b-sidebar
      id="sidebar-big"
      :width="checkExpansion.sidebarWidth"
      no-header
      no-close-on-route-change
      shadow
      :visible="sidebarVisibility"
    >
      <div class="sidebar">
        <div class="parent">
          <div @click="expanded = !expanded" class="hamburger">
            <Transition name="expansion" mode="out-in">
              <b-icon
                icon="chevron-bar-left"
                v-if="expanded"
                key="open"
              ></b-icon>
              <b-icon
                icon="chevron-bar-right"
                v-if="!expanded"
                key="closed"
              ></b-icon>
            </Transition>
          </div>
        </div>
        <hr />
        <div class="parent" v-if="hasRole(['federation'])">
          <router-link to="/pending-approvals"
            ><div :class="checkExpansion.itemClass" @click="test">
              <b-icon icon="clock" class="static-icon"></b-icon>
              <span :class="checkExpansion.titleClass">Onay Bekleyenler</span>
            </div></router-link
          >
        </div>
        <div class="parent" v-if="hasRole(['federation'])">
          <router-link to="/map"
            ><div :class="checkExpansion.itemClass" @click="test">
              <b-icon icon="map" class="static-icon"></b-icon>
              <span :class="checkExpansion.titleClass">Bilgi Bankası</span>
            </div></router-link
          >
        </div>
        <div class="parent" v-if="hasRole(['club'])">
          <router-link :to="{ name: 'EditClub', params: { id: getUser.id } }"
            ><div :class="checkExpansion.itemClass">
              <b-icon icon="gear" class="static-icon"></b-icon>
              <span :class="checkExpansion.titleClass">Bilgileri Güncelle</span>
            </div></router-link
          >
        </div>
        <div
          class="parent"
          v-if="hasRole(['provincialRepresentative', 'personel'])"
        >
          <router-link
            :to="{ name: 'EditPersonel', params: { id: getUser.id } }"
            ><div :class="checkExpansion.itemClass" @click="editPersonel">
              <b-icon icon="gear" class="static-icon"></b-icon>
              <span :class="checkExpansion.titleClass"
                >Bilgilerimi Güncelle</span
              >
            </div></router-link
          >
        </div>
        <div class="parent" v-if="hasRole(['federationkilit'])">
          <router-link to="/management">
            <div :class="checkExpansion.itemClass">
              <b-icon icon="pencil-square" class="static-icon"></b-icon>
              <span :class="checkExpansion.titleClass">Seçim Yönetimi</span>
            </div>
          </router-link>
        </div>
        <div class="parent" v-if="hasRole(['federation'])">
          <router-link to="/personel">
            <div :class="checkExpansion.itemClass">
              <b-icon icon="people-fill" class="static-icon"></b-icon>
              <span :class="checkExpansion.titleClass">Personeller</span>
            </div>
          </router-link>
        </div>
        <div class="parent" v-if="hasRole(['federation'])">
          <router-link to="/clubs">
            <div :class="checkExpansion.itemClass">
              <b-icon icon="terminal-fill" class="static-icon"></b-icon>
              <span :class="checkExpansion.titleClass">Kulüpler</span>
            </div>
          </router-link>
        </div>
        <div class="parent">
          <router-link to="/competitions">
            <div :class="checkExpansion.itemClass">
              <b-icon icon="ui-checks" class="static-icon"></b-icon>
              <span :class="checkExpansion.titleClass">Yarışmalar</span>
            </div>
          </router-link>
        </div>
        <div class="parent" v-if="hasRole(['federation'])">
          <router-link to="/guidebook">
            <div :class="checkExpansion.itemClass">
              <b-icon icon="book-fill" class="static-icon"></b-icon>
              <span :class="checkExpansion.titleClass">Rehber</span>
            </div>
          </router-link>
        </div>
        <!-- <div class="parent" v-if="hasRole(['federation'])">
          <div :class="checkExpansion.itemClass">
            <b-icon icon="sim" class="static-icon"></b-icon>
            <span :class="checkExpansion.titleClass">Toplu SMS</span>
          </div>
        </div> -->
        <div
          class="parent"
          v-if="hasRole(['federation', 'provincialRepresentative'])"
        >
          <router-link to="/reports">
            <div :class="checkExpansion.itemClass">
              <b-icon icon="file-earmark-ruled" class="static-icon"></b-icon>
              <span :class="checkExpansion.titleClass">Raporlar</span>
            </div>
          </router-link>
        </div>
        <div
          class="parent"
          v-if="hasRole(['federation', 'provincialRepresentative', 'personel','sportsReferee'])"
        >
          <router-link to="/messages">
            <div :class="checkExpansion.itemClass">
              <div>
                <b-icon icon="envelope" class="static-icon"></b-icon>
                <span :class="checkExpansion.titleClass"
                  >Mesajlar<b-badge class="ml-2" variant="primary">{{
                    getUnreadMessageCount
                  }}</b-badge></span
                >
              </div>
            </div>
          </router-link>
        </div>
        <div class="parent" v-if="hasRole(['federation'])">
          <router-link to="/settings"
            ><div :class="checkExpansion.itemClass" @click="test">
              <b-icon icon="gear" class="static-icon"></b-icon>
              <span :class="checkExpansion.titleClass">Ayarlar</span>
            </div></router-link
          >
        </div>
        <div class="parent">
          <router-link to="/faq">
            <div :class="checkExpansion.itemClass">
              <b-icon icon="question-circle" class="static-icon"></b-icon>
              <span :class="checkExpansion.titleClass">Yardım</span>
            </div>
          </router-link>
        </div>
        <div>
          <div class="close-button" @click="$emit('closeSidebar')">
            <b-icon icon="x" class="static-icon"></b-icon>
          </div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Sidebar",
  props: ["sidebarVisibility"],
  computed: {
    ...mapGetters([
      "getPersonelFilter",
      "getUser",
      "getClubImage",
      "getPersonelImage",
      "getUnreadMessageCount",
      "checkToken",
      "getScrollingText",
    ]),
    personelFilter: {
      get() {
        return this.getPersonelFilter;
      },
      set(value) {
        this.$store.commit("updatePersonelFilter", value);
      },
    },
    getUserName: {
      get() {
        return this.getUser.userName;
      },
    },
    scrollingText: {
      get() {
        return this.getScrollingText[this.scrollIndex];
      },
    },
    checkExpansion: {
      get() {
        if (this.expanded) {
          return {
            sidebarWidth: "24rem",
            titleClass: "open-title text-light",
            hamburgerIcon: "chevron-bar-left",
            itemClass: "expanded-item",
          };
        }
        return {
          sidebarWidth: "6rem",
          titleClass: "title text-light",
          hamburgerIcon: "chevron-bar-right",
          itemClass: "hover-item",
        };
      },
    },
  },
  data() {
    return {
      ssn: "",
      value: 5,
      scrollIndex: 0,
      expanded: false,
    };
  },
  created() {
    if (this.hasRole(["club"])) {
      this.$store.dispatch("initClubImages", this.getUser.id);
    } else if (this.hasRole(["personel", "provincialRepresentative"])) {
      this.$store.dispatch("initPersonelImage", this.getUser.id);
    }
  },
  methods: {
    searchBySSN(event) {
      event.preventDefault();

      if (!(this.$router.history.current.path == "/personel")) {
        this.$router.push({ name: "Personel" });
      }
      this.$store.dispatch("initPersonel");
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    repeatText() {
      if (this.scrollIndex < this.getScrollingText.length - 1) {
        this.scrollIndex++;
      } else {
        this.scrollIndex = 0;
      }
    },
    logout() {
      this.$store.dispatch("logout");
    },
    hasRole(roles) {
      let vals = [];
      roles.forEach((role) => {
        if (role == "federation") {
          vals.push(this.$store.getters.checkToken.isFederation);
        } else if (role == "personel") {
          vals.push(this.$store.getters.checkToken.isPersonel);
        } else if (role == "club") {
          vals.push(this.$store.getters.checkToken.isClub);
        } else if (role == "provincialRepresentative") {
          vals.push(this.$store.getters.checkToken.isProvincialRepresentative);
        } else if (role == "individual") {
          vals.push(this.$store.getters.checkToken.isIndividual);
        }else if (role == "sportsReferee") {
          vals.push(this.$store.getters.checkToken.sportsReferee);
        }
      });
      return vals.includes(true);
    },
    editPersonel() {
      this.$router.push({
        name: "EditPersonel",
        params: { id: this.getUser.id },
      });
    },
    getDashboardDestination() {
      if (this.hasRole(["federation"])) {
        return "/pending-approvals";
      } else if (this.hasRole(["club"])) {
        return { name: "ClubDetail", params: { id: this.getUser.id } };
      } else {
        return { name: "PersonelDetail", params: { id: this.getUser.id } };
      }
    },
    editClub() {
      this.$router.push({
        name: "EditClub",
        params: { id: this.getUser.id },
      });
    },
    test() {},
  },
};
</script>

<style scoped>
.hover-item:hover {
  cursor: pointer;
  width: 24rem;
  height: 4rem;
  color: azure;
  background: rgb(30, 30, 30);
  background: linear-gradient(
    30deg,
    rgba(30, 30, 30, 1) 0%,
    rgb(99, 99, 99) 100%
  );
  transition: width 0.3s, background 0.3s;
  position: absolute;
}
.expanded-item:hover {
  cursor: pointer;
  height: 4rem;
  color: azure;
  background: rgb(30, 30, 30);
  background: linear-gradient(
    30deg,
    rgba(30, 30, 30, 1) 0%,
    rgb(99, 99, 99) 100%
  );
  position: absolute;
}
.title {
  opacity: 0;
  visibility: collapse;
  position: absolute;
  margin-top: 0.8rem;
  align-self: right;
  left: 30%;
  margin-left: 0.5rem;
  font-size: 1.5rem;
}
.open-title {
  position: absolute;
  margin-top: 0.8rem;
  left: 30%;
  font-size: 1.5rem;
}
.hover-item:hover .title {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.4s linear 0.1s;
}
.hover-item:hover .open-title {
  position: absolute;
  opacity: 1;
  transition: opacity 0.4s linear 0.1s;
}
.hover-item:hover .b-icon {
  left: 30%;
}
.hover-item {
  color: azure;
  font-size: 2.5rem;
  width: 6rem;
}
.expanded-item {
  color: azure;
  font-size: 2.5rem;
  width: 24rem;
}
.static-icon {
  margin-left: 1.75rem;
}
.parent {
  height: 4rem;
}
.hamburger:hover {
  cursor: pointer;
  transform: translate(0.4rem, 0.4rem);
  font-size: 2.6em;
}
.hamburger {
  color: azure;
  font-size: 2.5rem;
  text-align: center;
  width: 5rem;
  transform: translate(0.4rem, 0.5rem);
}
.sidebar {
  width: 100%;
  height: 100%;
  background: linear-gradient(#2e6f9a, #3d93cd);
}
.close-button {
  position: absolute;
  bottom: 2rem;
  font-size: 2.5rem;
  color: azure;
}
.close-button:hover {
  transform: scale(1.3) translate(-0.15rem);
  cursor: pointer;
}
.expansion-leave-to {
  transform: rotate(180deg);
}
.expansion-leave-active {
  transition: transform 0.2s;
}
</style>
