<template>
  <div id="app">
    <Sidebar
      :sidebarVisibility="sidebarVisibility"
      @closeSidebar="closeSidebar"
    ></Sidebar>
    <Navbar @openSidebar="openSidebar"></Navbar>
    <b-alert show class="text-center scrolling-text">
      <transition name="slide-fade" mode="out-in">
        <p :key="scrollingText" style="margin-top: 1rem">
          {{ scrollingText }}
        </p>
      </transition>
    </b-alert>
    <b-container
      ><b-alert variant="danger" v-if="getError" class="mt-4" show
        ><b-icon-exclamation-octagon-fill class="mr-4" />{{ getError }}</b-alert
      ></b-container
    >
    <b-select v-show="false" v-model="getNotification"></b-select>
    <router-view />
    <br />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapGetters } from "vuex";
import Sidebar from "../../components/sidebar/Sidebar.vue";
export default {
  components: {
    Navbar,
    Sidebar,
  },
  data() {
    return {
      sidebarVisibility: true,
      scrollIndex: 0,
    };
  },
  computed: {
    ...mapGetters("notification", ["getError", "getNotifications"]),
    ...mapGetters(["getScrollingText"]),
    scrollingText: {
      get() {
        return this.getScrollingText[this.scrollIndex];
      },
    },
    getNotification: {
      get() {
        this.getNotifications.forEach((e) => {
          this.$bvToast.toast(e.body, {
            title: e.title,
            variant: e.type,
            solid: true,
          });
        });
        this.$store.dispatch("notification/setNotifications", []);
        return this.getNotifications;
      },
      set() {
        return this.getNotifications;
      },
    },
  },
  created() {
    this.$store.dispatch("getUnreadMessageCountFromServer", {
      id: this.$store.getters.getUser.id,
    });
    if (this.isMobile()) {
      this.sidebarVisibility = false;
    }
    this.$store.dispatch("initScrollingText").then(() => {
      setInterval(this.repeatText, 5000);
    });
  },

  methods: {
    openSidebar() {
      this.sidebarVisibility = true;
    },
    closeSidebar() {
      this.sidebarVisibility = false;
    },
    repeatText() {
      if (this.scrollIndex < this.getScrollingText.length - 1) {
        this.scrollIndex++;
      } else {
        this.scrollIndex = 0;
      }
    },
   
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  background-color: #dddddd;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
.scrolling-text {
  font-size: 1.2rem;
}
</style>